module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Newbody Family","short_name":"Newbody","theme_color":"#0d1e31","description":"Newbody hjälper skolor, lag och föreningar att tjäna pengar till cuper, träningsläger och skolresor.","background_color":"#FFFFFF","lang":"sv","start_url":"/","id":"newbody-family-info","display":"standalone","orientation":"portrait","legacy":false,"icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.newbodyfamily.com/sv-se"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://55230b556c2f49f2a59420d0dcf756d1@o245925.ingest.sentry.io/5444915","tracesSampleRate":0.1,"allowUrls":[{}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
