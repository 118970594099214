// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-language-select-js": () => import("./../../../src/pages/language-select.js" /* webpackChunkName: "component---src-pages-language-select-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-company-news-archive-js": () => import("./../../../src/templates/company-news-archive.js" /* webpackChunkName: "component---src-templates-company-news-archive-js" */),
  "component---src-templates-company-news-js": () => import("./../../../src/templates/company-news.js" /* webpackChunkName: "component---src-templates-company-news-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-archive-js": () => import("./../../../src/templates/post-archive.js" /* webpackChunkName: "component---src-templates-post-archive-js" */),
  "component---src-templates-post-category-js": () => import("./../../../src/templates/post-category.js" /* webpackChunkName: "component---src-templates-post-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

