/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('./src/styles/global.css')
const React = require('react')
const { PATH_PREFIX } = require('./node/functions')

/**
 * Add custom scripts
 * @param {*} url
 */
const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

/**
 * On client entry
 */
exports.onClientEntry = () => {
  window.onload = () => {
    addScript(PATH_PREFIX + 'vendor/modernizr.js')
  }
}

/**
 * On route change
 * @param {*} _ 
 * @param {*} pluginOptions 
 */
exports.onRouteUpdate = (_, pluginOptions) => {
  const pushDataLayer = event => {
    if (window.cookiebotAccept == true) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(event)
    } else {
      window.tempDataLayer = window.tempDataLayer || []
      window.tempDataLayer.push(event)
    }
  }
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      const pagePath = window.location?.pathname + window.location?.search
      const pageTitle = document?.title
      pushDataLayer({ 
        event: `route-change`,
        source: 'Info Web',
        pagePath: pagePath,
        pageTitle: pageTitle,
      })
      pushDataLayer({ 
        event: `gatsby-route-change`,
        source: 'Info Web',
        pagePath: pagePath,
        pageTitle: pageTitle,
        legacy: true
      })
    }, 50)
}